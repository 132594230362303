require("@rails/ujs").start()
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();
import $ from "jquery";

let app;

$(() => {
  return app.initialize();
})

app = {
  is_sp: (window.innerWidth < 768),
  setBind: () => {
    $('.humbeger').bind('click', () => {
      $('.humbeger').toggleClass('open')
      $('header ul').toggleClass('open')
    })
    $('a.in-page').bind('click', (e) => {
      e.preventDefault();
      $('.humbeger').removeClass('open')
      $('header ul').removeClass('open')
      document.querySelector($(e.currentTarget).attr("href")).scrollIntoView({
        behavior: "smooth",
        block: app.is_sp ? "start" : "center"
      });
    })
  },
  initialize: () => {
    app.setBind()
  }
}